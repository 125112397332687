import React from 'react';
import { useNavigate } from 'react-router-dom';

const CallToActionBanner = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/servicios');
  };

  return (
    <section className="bg-gray-800 text-white py-12 rounded-lg shadow-md text-center mt-8">
      <div className="mx-auto px-4">
        <h2 className="text-3xl lg:text-4xl font-bold mb-4">¿Problemas con tu Teléfono?</h2>
        <p className="text-lg lg:text-xl mb-6 px-8">
          Ofrecemos el mejor servicio de reparación para dispositivos Apple. ¡Confía en nuestros expertos!
        </p>
        <button
          onClick={handleClick}
          className="bg-white text-gray-800 font-semibold py-2 px-6 rounded-full shadow-md hover:bg-gray-100 transition duration-300"
        >
          Ver Servicios
        </button>
      </div>
    </section>
  );
};

export default CallToActionBanner;

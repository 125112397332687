// components/Logo.jsx
import React from 'react';
import PropTypes from 'prop-types';

function Logo({ size, className }) {
  const timestamp = new Date().getTime(); // Genera un timestamp único
  const logoUrl = `/images/logo.png?timestamp=${timestamp}`;

  return (
    <div className={`flex justify-center items-center ${className}`}>
      <img
        src={logoUrl}
        alt="Logo de la compañía"
        className="rounded-full shadow shadow-gray-200 bg-gray-950"
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    </div>
  );
}

Logo.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import useCompanyInfo from '../components/useCompanyInfo';
import RedesSocialesButton from '../components/RedesSocialesButton';
import { CiFacebook } from "react-icons/ci";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import sanitizeHtml from 'sanitize-html';

const ThankYou = () => {
  const companyInfoData = useCompanyInfo();

  if (!companyInfoData) {
    return <div className="text-center py-8">Loading...</div>;
  }

  const sanitizedDescription = sanitizeHtml(companyInfoData.descripcion);
  const sanitizedThankYouMessage = sanitizeHtml(
    `Nos complace haberte atendido y esperamos que disfrutes de tu compra. <br/>Si tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos.`
  );

  return (
    <div className="container mx-auto p-4 mt-16">
      <Helmet>
        <title>{companyInfoData.title} - Gracias</title>
        <meta name="description" content="Gracias por tu compra" />
        <meta property="og:title" content={`Gracias por comprar en ${companyInfoData.title}`} />
        <meta property="og:description" content="Gracias por tu compra" />
        <meta property="og:image" content="/images/image-base.jpg" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={companyInfoData.title} />
        <meta property="article:author" content={companyInfoData.title} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={`Gracias por comprar en ${companyInfoData.title}`} />
        <meta property="twitter:description" content="Gracias por tu compra" />
        <meta property="twitter:image" content="/images/image-base.jpg" />
      </Helmet>

      {/* Thank You Section */}
      <section className="relative bg-gray-800 text-white mb-8 rounded-lg shadow-md overflow-hidden">
        <img src="/images/image-base.jpg" alt="Gracias" className="w-full h-96 object-cover opacity-95" />
        <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
        <div className="relative z-10 text-center p-12">
          <h1 className="text-3xl lg:text-5xl font-bold mb-4">Gracias por tu compra</h1>
          <p className="text-xl" dangerouslySetInnerHTML={{ __html: sanitizedThankYouMessage }}></p>
        </div>
      </section>

      {/* Company Info Section */}
      <section className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4">Información de Contacto</h2>
        <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></p>
        <p className="text-gray-600 mt-4"><strong>Correo Electrónico:</strong> {companyInfoData.eMail}</p>
        <p className="text-gray-600"><strong>Teléfono:</strong> {companyInfoData.telefonoWhatsApp}</p>
        <p className="text-gray-600"><strong>Dirección:</strong> {companyInfoData.direccion}</p>
        <p className="text-gray-600"><strong>Ubicación:</strong> {companyInfoData.location}</p>
      </section>

      {/* Follow Us Section */}
      <section className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4">Síguenos en Redes Sociales</h2>
        <RedesSocialesButton />
      </section>

    </div>
  );
};

export default ThankYou;

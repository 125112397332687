import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import sanitizeHtml from 'sanitize-html';
import useCompanyInfo from '../components/useCompanyInfo';

const Services = () => {
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    issue: '',
  });

  const companyInfoData = useCompanyInfo();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const timestamp = new Date().getTime(); // Generar timestamp
        const response = await axios.get(`/panel/servicios/servicios.json?timestamp=${timestamp}`);
        setData(response.data);
      } catch (error) {
        console.error('Error loading JSON data:', error);
      }
    };

    fetchData();
  }, []);

  if (!data || !companyInfoData) {
    return <div className="text-center py-8">Loading...</div>;
  }

  const createMarkup = (htmlContent) => {
    return { __html: sanitizeHtml(htmlContent) };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, issue } = formData;
    const message = `Hola, mi nombre es ${firstName} ${lastName}. Mi correo es ${email} y mi teléfono es ${phone}. Tengo el siguiente problema con mi teléfono: ${issue}.`;
    const whatsappUrl = `https://wa.me/${companyInfoData.telefonoWhatsApp}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="container mx-auto p-4 mt-16">
      <Helmet>
        <title>{companyInfoData.title} - Servicios</title>
        <meta name="description" content="Servicio técnico especializado en dispositivos Apple" />
        <meta property="og:title" content={`Servicios de ${companyInfoData.title}`} />
        <meta property="og:description" content="Servicio técnico especializado en dispositivos Apple" />
        <meta property="og:image" content="/panel/servicios/hero-image.webp" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={companyInfoData.title} />
        <meta property="article:author" content={companyInfoData.title} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={`Servicios de ${companyInfoData.title}`} />
        <meta property="twitter:description" content="Servicio técnico especializado en dispositivos Apple" />
        <meta property="twitter:image" content="/panel/servicios/hero-image.webp" />
      </Helmet>

      {/* Hero Section */}
      <section className="relative bg-gray-800 text-white mb-8 rounded-lg shadow-md overflow-hidden">
        <img src="/panel/servicios/servicios.jpg" alt="Hero" className="w-full h-96 object-cover opacity-95"/>
        <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
        <div className="relative z-10 text-center p-12">
          <h1 className="text-3xl lg:text-5xl font-bold mb-4">Bienvenido a {companyInfoData.title}</h1>
          <p className="text-xl">Servicio técnico especializado en Apple</p>
        </div>
      </section>
      
      {/* About Us Section */}
      <section className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4">Sobre Nosotros</h2>
        <p className="text-gray-600" dangerouslySetInnerHTML={createMarkup(companyInfoData.descripcion)}></p>
      </section>

      {/* Services Section */}
      <section className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4">Servicios</h2>
        {data.services.map((service, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-xl font-semibold text-gray-800">{service.service_name}</h3>
            <p className="text-gray-600" dangerouslySetInnerHTML={createMarkup(service.description)}></p>
          </div>
        ))}
      </section>

      {/* Why Choose Us Section */}
      <section className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4">¿Por Qué Elegirnos?</h2>
        <ul className="list-disc pl-5 text-gray-600">
          {data.why_choose_us.map((reason, index) => (
            <li key={index} dangerouslySetInnerHTML={createMarkup(reason)}></li>
          ))}
        </ul>
      </section>

      {/* Contact Form Section */}
      <section className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8 shadow-md rounded-lg mb-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Formulario de Contacto</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Complete el formulario a continuación para enviar su problema a nuestro equipo de soporte.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                Nombre
              </label>
              <div className="mt-2.5">
                <input
                  id="first-name"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                Apellido
              </label>
              <div className="mt-2.5">
                <input
                  id="last-name"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Correo Electrónico
              </label>
              <div className="mt-2.5">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                Teléfono
              </label>
              <div className="relative mt-2.5">
                <input
                  id="phone-number"
                  name="phone"
                  type="tel"
                  autoComplete="tel"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="issue" className="block text-sm font-semibold leading-6 text-gray-900">
                Descripción del Problema
              </label>
              <div className="mt-2.5">
                <textarea
                  id="issue"
                  name="issue"
                  rows={4}
                  value={formData.issue}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Enviar a WhatsApp
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Services;
